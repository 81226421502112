<template>
	<div class="media">
		<div class="banner">
			<div class="one">Legal & Privacy</div>
		</div>
		<div class="body">
			<div class="left">
				<div class="title1" ref="aaa">Terms of Service</div>
				<div class="title2">Other applicable terms</div>
				<div class="font">
					These General Terms of Use shall be supplemented by the Voobank’s Privacy Policy, which
					sets out information about the cookies on Voobank Website and the terms with which Voobank
					may process any personal data collected from you or that you provided to us. By accessing
					and using Voobank Website, you consent to such processing and you warrant that all data
					provided by you is accurate.
					<br />
					These General Terms of Use shall also be supplemented by any other applicable terms that
					are specific to Voobank’s products and services, and shall collectively govern your access
					and use of the Voobank’s products and services and the Voobank Website.
				</div>
				<div class="title2">Changes to these Terms of Use and Voobank Website</div>
				<div class="font">
					We reserve the right to supplement, vary, amend or update any of the terms of these
					General Terms of Use or any other applicable terms and the Voobank Website from time to
					time. While we endeavour to notify you of any such supplement, variation, amendment or
					update by posting on the Voobank Website or following such other delivery method as
					reasonably determined by Voobank, we are under no obligation to provide any prior notice.
					<br />
					It is ultimately your responsibility to check the Voobank Website from time to time to
					take notice of any changes Voobank makes, as they are legally binding on you. If you do
					not raise any opposition to any proposed supplement, variation or amendment and continue
					to access and use Voobank’s products and services, you shall be deemed to have read,
					understood and agreed to the changes or updates made.
				</div>
				<div class="title2">Notices and communication</div>
				<div class="font">
					You may refer to our Support page available on the Voobank Website for any general support
					issues or you can contact us via our email or online chat. Contact details can be found on
					the Voobank Website.
					<br />
					It is your responsibility to ensure that you are able to receive the emails and
					communications we send you. You consent to accept any notices, emails or communications
					electronically and understand that we may send notices to you directly through the
					Voobank’s dashboard provided to you or to the email addresses or phone numbers you
					provided to us.
				</div>
				<div class="title2">Accessing Voobank Website</div>
				<div class="font">
					We do not guarantee that the Voobank Website, or any content on it, will always be
					available or be uninterrupted. Any content published on the Voobank Website may be out of
					date at any given time and we do not guarantee that the Voobank Website, or any content on
					it, will be free from any errors or omissions.
					<br />
					We may suspend, withdraw, discontinue or change all or any part of the Voobank Website or
					its products or services without notice. Voobank will not be liable to you if for any
					reason the Voobank Website or Voobank’s products and services are unavailable at any time
					or for any period. You are responsible for making all arrangements necessary for you to
					have access to the Voobank Website. You are also responsible for ensuring that all persons
					who access the Voobank Website through your internet connection are aware of these General
					Terms of Use and any other applicable terms and conditions that Voobank may have specific
					to its products and services and that they comply with them.
				</div>
				<div class="title2">Account management and password</div>
				<div class="font">
					Where a user identification code, email, password or other pieces of information is
					required in order to use the Voobank Website or Voobank’s products and service, such
					information is kept confidential by Voobank. Voobank has the right to disable any user
					identification code, email or password if, in Voobank’s reasonable opinion, you have
					failed to comply with any of the provisions of the terms and conditions.
					<br />
					If you know or suspect that anyone other than you or your authorised personnel knows your
					user identification code, email or password, you must promptly notify us at xxx@xxxx.
				</div>

				<div class="title2">Know-Your-Customer & Anti-Money Laundering procedures</div>
				<div class="font">
					We may, at our sole discretion or as otherwise required by law, conduct appropriate
					Know-Your-Customer (KYC) and/or Anti-Money Laundering (AML) procedures, for which you will
					need to register in full and provide us with specified documents to prove and establish
					your identity.
					<br />
					During registration, you may need to provide, including but not limited to, your full name
					and/or the full legal name of the entity that you represent or acting on behalf of,
					whenever applicable, residential address or incorporation/business address, date of birth
					or date of incorporation, nationality, contact number, and email address.
					<br />
					The KYC documentation that we may request may include, but not limited to the following:
					<ul>
						<li>
							Proof of identity or incorporation, including any relevant business registration
							documents, annual returns etc.;
						</li>
						<li>Proof of address/financial documents, such as bank statements;</li>
						<li>Articles of Association & shareholding chart (if applicable);</li>
						<li>
							Proof of source of funds or wealth (if applicable), for example investment statements
							or payslips.
						</li>
					</ul>
					<br />
					We may suspend, terminate or reject your account or the business relationship until a
					satisfactory identification document or documentary evidence on the source of funds or
					wealth is provided. If any of your KYC documentation expires or is no longer valid, we
					have the right to request an additional valid and non-expired KYC documentation from you.
					<br />
					You agree to allow your information (individual or corporate) to be disclosed to Voobank’s
					service providers for the purposes of KYC verification and any other screening checks. You
					further agree, represent and warrant that by providing us your information, including
					information on any related or connected parties, you undertake that the disclosure of any
					personal data to Voobank is for our purpose and is within the scope of consent given to
					you by such related or connected parties.
				</div>
				<div class="title2">Legitimate use</div>
				<div class="font">
					You agree to use the Voobank Website and Voobank’s products and services only for
					legitimate purposes. You may not use the Voobank Website:
					<br />
					<ul>
						<li>
							In any way that breaches any applicable local or international law or regulation;
						</li>
						<li>
							In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose
							or effect;
						</li>
						<li>
							To send, knowingly receive, upload, download, use or re-use any material which does
							not comply with our content standards;
						</li>
						<li>
							To transmit, or procure the sending of, any unsolicited or unauthorised advertising or
							promotional material or any other form of similar solicitation (spam);
						</li>
						<li>
							To generate and/or cause congestion to our network traffic in excess of reasonable and
							normal usage;
						</li>
						<li>
							To knowingly transmit any data, send or upload any material that contains viruses,
							Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other
							harmful programs or similar computer code designed to adversely affect the operation
							of any computer software or hardware;
						</li>
						<li>
							To interfere with, damage (or attempt to interfere with or damage) or reverse-engineer
							any code, data or software on or associated with the Voobank Website.
						</li>
					</ul>
				</div>
				<div class="title2">Representations and warranties</div>
				<div class="font">
					You represent and warrant that you will not use Voobank for the purpose of money
					laundering, or to facilitate any illegal transactions or to permit others to use Voobank
					for personal, family or household purposes.
					<br />
					You further represent and warrant that you will not use Voobank to enable any person,
					including you, to benefit from any activities Voobank has identified as a restricted
					business activity or any activity that we may inform you as a restricted business or
					activity. This includes, but not limited to:
					<ul>
						<li>
							Use of any Voobank’s products and services in or for the benefit of a country,
							organization, entity, or person embargoed or blocked by any government, including
							those on sanctions lists identified by OFAC or any other applicable regulatory
							authorities;
						</li>
						<li>
							Access or attempt to access non-public Voobank’s systems, programs, data, or services
							without authorisation;
						</li>
						<li>
							Copy, reproduce, republish, upload, post, transmit, resell, or distribute in any way,
							any data, content, or any part of the Voobank’s products and services and Voobank
							Website, including any API documentations that may be circulated to you;
						</li>
						<li>
							Act as service bureau or pass-through agent of any kind with no added value to
							end-users and customers;
						</li>
						<li>
							Transfer any rights granted to you under these General Terms of Use and any other
							applicable terms specific to Voobank’s products and services to any third-parties;
						</li>
						<li>
							Work around any of the technical limitations of the Voobank’s products or services,
							including any of its own proprietary platform or enable any functionality that is
							disabled or prohibited;
						</li>
						<li>
							Intentionally impose any unreasonable or disproportionately large load on Voobank’s
							products or services, including on Voobank’s proprietary platform; or
						</li>
						<li>
							Any other activity that Voobank deems as restricted business or activity, from time to
							time.
						</li>
					</ul>
				</div>
				<div class="title2">WHAT IF I DO NOT WANT TO ACCEPT THE POLICY?</div>
				<div class="font">
					By using our Services, you acknowledge that we process information in the ways described
					by the Policy. If you do not agree to this policy, please do not use our Services. Your
					continued use of the Services following the posting of changes to this policy will be
					deemed as your acceptance of those changes.
					<br />
					If you already have registered an account on the Site, but you do not agree with the
					Policy, you shall terminate your account.
				</div>
				<div class="title2">CONTACTS</div>
				<div class="font">
					If you have any questions about the Policy or would like to exercise any of your rights,
					please contact us at xxx@xxxxx.
				</div>

				<div class="title1" ref="bbb">Privacy Policy</div>

				<div class="title2">Information collected automatically</div>
				<div class="font">
					We may collect information about Users whenever they interact with our Service, for
					example, through the use of cookies. Such information may include the browser name, the
					type of computer and technical information about Users means of connection to our Site,
					such as IP address, location, date and time of connection, the operating system and the
					Internet service providers utilized and other similar information.
				</div>
				<div class="title2">Web browser cookies</div>
				<div class="font">
					We may collect information about Users whenever they interact with our Service, for
					example, through the use of cookies. Such information may include the browser name, the
					type of computer and technical information about Users means of connection to our Site,
					such as IP address, location, date and time of connection, the operating system and the
					Internet service providers utilized and other similar information.
				</div>
				<div class="title2">HOW LONG WE RETAIN INFORMATION</div>
				<div class="font">
					We store collected information for as long as it is necessary to perform our contractual
					obligations in order to provide products and services to you. If you decide to delete your
					account, do not use Services and choose to terminate contract with us, we keep your
					information and information on your transactions for up to 8 years after you delete your
					account for the compliance with the requirements of antimoney laundering laws and other
					legal acts. However, the information may be, wholly or in, part, retained for longer or
					shorter term if required by applicable laws or if there is another justified reason to
					retain or delete them.
					<br />
					In some cases, we can keep your information longer than 8 years: such exceeding can be
					used only in a case of arising of legal requirements that will oblige us to store your
					information for more than 8 years. Information associated with your account will be kept
					until your account is deleted, unless we no longer need the data to provide products and
					services.
					<br />
					Please note that retention periods may be changed from time to time based on legal and
					regulatory requirements.
				</div>
				<div class="title2">HOW WE SHARE COLLECTED INFORMATION</div>
				<div class="font">
					We sometimes need to share personal information we process with third parties. When
					necessary or required we may share information with authorities, other companies within
					the group of companies for internal administrative purposes, companies which the group
					cooperates with and to other third parties, such as third party service providers used in
					connection with our Services (e.g. various analytical services and financial institutions
					that we have partnered with to jointly create and offer a, product). The legal basis for
					data transfer is performance of contract between you and us, as well as our legitimate
					interest and our legal obligation.
					<br />
					We may share collected information with:
					<ul>
						<li>credit reference agencies;</li>
						<li>Identity verification, KYC and fraud prevention providers;</li>
						<li>debt collection and tracing agencies;</li>
						<li>banking and financial services partners and payments networks;</li>
						<li>suppliers and services providers (IT, payment, analytical, financial, etc.);</li>
						<li>
							companies specializing in manufacturing, personalization, and delivery of Visa and
							Mastercard cards;
						</li>
						<li>government bodies and other recipients in order to comply with applicable law;</li>
						<li>courts and tribunals.</li>
					</ul>
				</div>
				<div class="title2">
					CROSS-BORDER TRANSFERS
				</div>
				<div class="font">
					We intend to transfer the collected information we obtain from you to recipients in countries other than the country in which the information has been originally collected. Those countries may not have the same data protection laws as the country in which you initially provided the information.
					<br />
					The collected information may be transferred to a state outside the European Economic Area (“EEA”). For such transfers we use standard contract clauses or other suitable safeguard to permit data transfers from the EEA to other countries. A copy of standard data protection clauses adopted by a supervisory authority may be obtained by request made to the xxxx@xxxxx.
				</div>
				<div class="title2">
					HOW WE STORE AND PROTECT COLLECTED INFORMATION
				</div>
				<div class="font">
					We adopt appropriate data collection, storage and processing practices and security measures to protect from unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site. We use encryption to protect information and we grant access to information to our employees and third parties only when it is necessary for them to process said information.
					<br/>
					However, the transmission of such data over the Internet using personal computers or mobile devices is not completely safe and therefore we cannot guarantee the security of documents submitted to our platform. Any transmission of such documents is at your own risk. As soon as we receive your information, we implement strict security measures and procedures to avoid unauthorized access from any third party.
				</div>
				<div class="title2">
					RIGHTS OF USERS
				</div>
				<div class="font">
					You have a right to request from us:
					<br/>
					1.access to information and update information;
					<br/>
					You have a right to review information we keep about you and if you suppose that information is inaccurate or incomplete, you may contact us in order to update information. Updating your information. If you believe that the information we hold about you is inaccurate or that we are no longer entitled to use it and want to request its rectification, deletion or object to its processing, please contact us.
					<br/>
					1.restriction of processing and erasure of your personal data;
					<br/>
					If you believe that we are not entitled to use your information and you want to request its deletion or object to its processing, you may contact us.
					<br/>
					1.personal data in a structured, commonly used and machine-readable format.
					<br/>
					Please note that the above is subject to limitations by relevant data protection laws. You may exercise the aforementioned rights by sending a request to e-mail address provided in the Contacts section. In your request please provide us with your account login and we will respond you shortly for further discussion
					<br/>
					Where the processing is based on consent, you have a right to withdraw such consent at any time. However, we might keep processing information if we have a legal basis for it.
					<br/>
					We will not keep outdated or deleted information, unless we have to do so in accordance with legislative requirements.
				</div>

				<div class="title2">
					CHANGES TO THE POLICY
				</div>
				<div class="font">
					Voobank Digital Asset Card has the discretion to update this privacy policy at any time. When we do, we revise the updated date at the top of the page. We may also post a notification on the main page of our Site and notify you by other means (for example, via email). We encourage Users to frequently check the page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
				</div>

				<div class="title2">
					WHAT IF I DO NOT WANT TO ACCEPT THE POLICY?
				</div>

				<div class="font">
					By using our Services, you acknowledge that we process information in the ways described by the Policy. If you do not agree to this policy, please do not use our Services. Your continued use of the Services following the posting of changes to this policy will be deemed as your acceptance of those changes.
					<br/>
					If you already have registered an account on the Site, but you do not agree with the Policy, you shall terminate your account.
				</div>

				<div class="title2">
					CONTACTS
				</div>
				<div class="font">
					If you have any questions about the Policy or would like to exercise any of your rights, please contact us at xxx@xxxxx.
				</div>




				<div class="title1" ref="ccc">M3DAO Brand Logotype</div>
				<div class="title2">Restricted Jurisdictions</div>
				<div class="font">
					Please see the list of Prohibited Countries below and the Voobank's General Terms of Use
					for restrictions and eligibility requirements to open and maintain an account with
					Voobank.
					<ul>
						<li>Canada</li>
						<li>Netherlands</li>
						<li>United States</li>
						<li>Cuba</li>
						<li>Democratic People’s Republic of North Korea (“DPRK”)</li>
						<li>Iran</li>
						<li>Syria</li>
						<li>Crimea region and any non-government controlled areas of Ukraine</li>
					</ul>
				</div>
				<div class="title2">KYC</div>
				<div class="font">
					Voobank requires mandatory KYC to be undertaken to onboard any users in order to comply
					with legal and regulatory obligations including, but not limited to, rules governing
					anti-money laundering, counter-terrorism financing and sanctions; Voobank has created a
					number of educational resources to help users understand the KYC process, why it’s
					important and why we do it. These resources can be accessed here.
				</div>
				<div class="title2">Restrictions</div>
				<div class="font">
					We have notices regarding certain restrictions placed on our products and services which
					can be accessed here. Users impacted by these restrictions are strongly encouraged to
					review these notices and seek independent advice. Please see the Voobank General Terms of
					Use for a comprehensive list of restrictions and eligibility requirements to open and
					maintain an account on xxxxx.
				</div>
				<div class="title2">Customer Service</div>
				<div class="font">
					If you have any questions or complaints about Voobank’s products or services, please
					contact our customer service team who will be able to assist you. They are staffed 24
					hours a day, 7 days a week and strive to address your concerns as quickly as possible.
				</div>
			</div>
			<div class="navright">
				<div
					class="item"
					:class="{ active: active == 'aaa' ? true : false }"
					@click="scrollToAnchor('aaa')"
				>
					Terms of Service
				</div>
				<div
					class="item"
					:class="{ active: active == 'bbb' ? true : false }"
					@click="scrollToAnchor('bbb')"
				>
					Privacy Policy
				</div>
				<div
					class="item"
					:class="{ active: active == 'ccc' ? true : false }"
					@click="scrollToAnchor('ccc')"
				>
					Compliance Statement
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue';
const aaa = ref(null);
const bbb = ref(null);
const ccc = ref(null);
const active = ref('aaa');
const scrollToAnchor = (ref) => {
	active.value = ref;
	if (ref == 'aaa') {
		aaa.value.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
	} else if (ref == 'bbb') {
		bbb.value.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
	} else if (ref == 'ccc') {
		ccc.value.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
	}
};
const go = (url) => {
	window.open(url, '_blank');
};
</script>
<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.media {
		width: 100%;
		box-sizing: border-box;
		background: #000000;
		color: #ffffff;

		.banner {
			width: 100%;
			height: 730px;
			background: url('https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmdaREzatmSTy54bVKAWtJrnaVzfvZzkw1MYbCotZKxj4U');
			background-size: 1920px 730px;
			padding: 150px 360px 280px;
			.one {
				text-align: center;
				font-size: 50px;
				font-weight: 500;
				color: #ca8232;
			}
		}
		.body {
			width: 100%;
			padding: 0 360px 100px;
			display: flex;
			justify-content: space-between;
			position: relative;
			margin-top: -168px;
			.left {
				width: 840px;
				.title1 {
					font-weight: 700;
					font-size: 40px;
					margin-bottom: 40px;
					color: #ca8232;
					margin-top: 200px;
				}
				.title1:first-child {
					margin-top: 0px;
				}
				.title2 {
					font-weight: 600;
					font-size: 32px;
					margin-bottom: 10px;
				}
				.font {
					font-size: 22px;
					font-weight: 400;
					margin-bottom: 40px;
					line-height: 27.5px;
					li {
						margin-left: 10px;
					}
				}
			}
			.navright {
				position: sticky;
				top: 180px;
				right: 360px;
				width: 230px;
				height: 201px;
				.active {
					background: #ca8232;
				}
				.item {
					width: 100%;
					height: 53px;
					padding: 10px;
					color: #ffffff;
					margin-bottom: 21px;
					font-size: 18px;
					font-weight: 400;
				}
				.item:nth-last-child(1) {
					margin-bottom: 0;
				}
				.item_active {
					background: #ca8232;
				}
				.item:hover {
					background: #ca8232;
				}
			}
		}
	}
}


@media screen and (max-width: 768px) {
	.media {
		width: 100%;
		box-sizing: border-box;
		background: #000000;
		color: #ffffff;

		.banner {
			width: 100%;
			height: 381px;
			background: url('https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmZpAWvJLhf8cghgY5JGgUeombLEVjTk5UgnF7F3MHzYEy') no-repeat;
			background-size: 375px auto;
			padding: 100px 30px 110px;
			.one {
				text-align: center;
				font-size: 24px;
				font-weight: 700;
				color: #ca8232;
			}
		}
		.body {
			width: 100%;
			padding: 0 30px 30px;
			display: flex;
			justify-content: space-between;
			position: relative;
			margin-top: -150px;
			.left {
				width: 312px;
				.title1 {
					font-weight: 700;
					font-size: 22px;
					margin-bottom: 40px;
					color: #ca8232;
					margin-top: 80px;
				}
				.title1:first-child {
					margin-top: 0px;
				}
				.title2 {
					font-weight: 600;
					font-size: 22px;
					margin-bottom: 10px;
				}
				.font {
					font-size: 12px;
					font-weight: 400;
					margin-bottom: 30px;
					line-height: 27.5px;
					li {
						margin-left: 10px;
					}
				}
			}
			.navright {
				display: none;
			}
		}
	}
}
</style>
